<template>
  <div class="stage" ref="stage">
    <div class="stage__slider-wrapper swiper-container">
      <ul class="stage__wrapper swiper-wrapper" :class="{'stage__wrapper--bar': OS !== 'MacOS'}" ref="slider">
        <li class="stage__slide swiper-slide" v-for="(image, i) in images" :key="i">
          <img
            class="stage__image"
            :src="`${image.l['1x']}`"
            sizes="(max-width: 1440px) 100vw, 61vw"
            :srcset="
              `${image.xs['1x']}  400w,
              ${image.s['1x']}  768w,
              ${image.m['1x']}  1024w,
              ${image.l['1x']}  1920w`"
            :alt="image.alt"
            :data-index="i"
            :ref="`image-${i}`"
          />
        </li>
      </ul>
      <div v-if="images.length > 1" class="stage__controls stage__controls--next">
        <iconNext/>
      </div>
      <div v-if="images.length > 1" class="stage__controls stage__controls--prev">
        <iconPrev/>
      </div>
      <div v-if="images.length > 1" class="swiper-pagination"/>
    </div>
    <div class="stage__content-wrapper">
      <transition name="slide-fade">
        <div v-if="currentText.show" class="stage__content-inner-wrapper">
          <div v-if="currentText.breadcrumbs" class="stage__breadcrumbs">
            <ul class="stage__breadcrumb-list">
              <li
                v-for="(obj, i) in currentText.breadcrumbs"
                :key="i"
                class="stage__breadcrumb-list-item"
              >
                <a @click.prevent="slideTo(obj.slideLink)">{{obj.label}}</a>
              </li>
            </ul>
          </div>
          <vue-markdown :source="currentText.text"/>
          <ul class="stage__link-wrapper">
            <li
              class="stage__link-item"
              v-for="(link, i) in currentText.button"
              :key="i"
            >
              <a
                class="stage__link stage__link--bold"
                v-if="link.href"
                :href="link.href"
                target="_blank"
              >
                  {{ link.label }}
                  <iconNext class="stage__link-icon"/>
              </a>
            </li>
          </ul>
          <ul v-if="images.length > 1" class="stage__slider-trigger-list">
            <li class="stage__slider-trigger-list-item">
              <a
                class="stage__slider-trigger"
                @click.prevent="slidePrev"
              >
                <iconPrev class="stage__slider-trigger-icon"/>
              </a>
            </li>
            <li class="stage__slider-trigger-list-item">
              <a
                class="stage__slider-trigger"
                @click.prevent="slideNext"
              >
                <iconNext class="stage__slider-trigger-icon"/>
              </a>
            </li>
          </ul>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Swiper, { Navigation, Pagination } from 'swiper/core'
import VueMarkdown from 'vue-markdown'
import iconNext from '@/components/icons/next.vue'
import iconPrev from '@/components/icons/prev.vue'
import axios from 'axios'
import { mapActions, mapState } from 'vuex'

Swiper.use([Navigation, Pagination])
export default {
  name: 'Stage',
  components: {
    VueMarkdown,
    iconNext,
    iconPrev
  },
  props: {
    content: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      images: this.content.slides,
      texts: this.content.texts,
      swiperInstance: null
    }
  },
  computed: {
    ...mapState([
      'OS'
    ]),
    currentSlide () {
      return this.swiperInstance?.activeIndex
    },
    currentText () {
      if (this.texts[this.currentSlide] && !this.texts[this.currentSlide].text) {
        this.getCurrentText()
      }
      if (this.texts[this.currentSlide]) {
        return this.texts[this.currentSlide]
      } else {
        return this.texts[this.texts.length - 1]
      }
    }
  },
  created () {
    this.setStageVisible(true)
  },
  mounted () {
    this.updateSwiper()
  },
  methods: {
    ...mapActions([
      'setStageVisible'
    ]),
    updateSwiper () {
      this.swiperInstance = new Swiper('.swiper-container', {
        direction: 'horizontal',
        pagination: {
          el: '.swiper-pagination'
        },

        // Navigation arrows
        navigation: {
          nextEl: '.stage__controls--next',
          prevEl: '.stage__controls--prev'
        }
      })
    },
    async getCurrentText () {
      axios.get(this.content.texts[this.currentSlide].href)
        .then(response => {
          this.$set(this.texts[this.currentSlide], 'text', response.data)
          this.$set(this.texts[this.currentSlide], 'show', true)
        })
        .catch(e => {
          console.log(e, 'No text available')
        })
    },
    slideNext () {
      this.swiperInstance.slideNext()
    },
    slidePrev () {
      this.swiperInstance.slidePrev()
    },
    slideTo (index) {
      this.swiperInstance.slideTo(index)
    }
  }
}
</script>
<style lang="scss">
  @import "@/styles/swiper.scss";
</style>
<style scoped lang="scss">
  .stage {
    display: flex;
    width: 100%;
    min-height: 100vh;
    height: 100%;
    position: relative;
    padding: 1rem 1rem 2rem;
    flex-wrap: wrap;
    overflow: hidden;
    @media (min-width: 1024px) {
      padding: calc(100px + 2rem) 2rem;
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        height: calc(100% + 3rem);
        width: 38.2%;
        background-color: #0F6CB7;
        z-index: -1;
      }
    }
    &__controls {
      display: none;
    }
    @media (min-width: 1024px) {
      &__controls {
        position: absolute;
        top: calc(50% - 15px);
        width: 200px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 2rem;
        box-shadow: inset 0 0 -10px 1px rgba(0,0,0,.3);
        z-index: 1;
        top: 0;
        transition: transform 300ms;
        cursor: pointer;
        & svg {
          width: 40px;
          height: 40px;
          color: #fff;
          opacity: .6
        }
        &--next {
          box-shadow: inset -165px 0px 80px -65px rgba(0, 0, 0, .6);
          right: 0;
          justify-content: flex-end;
          transform: translate(200px,0);
          border-top-right-radius: 20px;
          border-bottom-right-radius: 20px;
        }
        &--prev {
          box-shadow: inset 165px 0px 80px -65px rgba(0, 0, 0, .6);
          left: 0;
          justify-content: flex-start;
          transform: translate(-200px,0);
          border-top-left-radius: 20px;
          border-bottom-left-radius: 20px;
        }
      }
    }
    &__slider-wrapper {
      border-radius: 20px;
      box-shadow: 0px 20px 20px -10px rgba(120,120,120,.3);
      width: 100%;
      height: 100%;
      ul {
        list-style-type: none;
        padding: 0;
        margin: 0;
      }
      @media (min-width: 1024px) {
        max-width: 100%;
        &:hover {
          .stage__controls {
            transform: translate(0,0);
          }
        }
      }
      @media (min-width: 1440px) {
        max-width: calc((100% - 38.2%) + 2rem);
        margin-right: 2rem;
        &:hover {
          .stage__controls {
            transform: translate(0,0);
          }
        }
      }
    }
    &__content-wrapper {
      width: 100%;
      h1 {
          hyphens: manual;
        }
      @media (min-width: 1024px) {
        display: flex;
        flex:1;
        min-width: 230px;
        max-width: 60%;
        flex-direction: column;
        font-size: 1.15em;
        h1 {
          font-size: 2.3em;
        }
      }
      @media (min-width: 1440px) {
        color: #ffffff;
        max-width: 100%;
      }
    }
    &__wrapper {
      display: flex;
      @media (min-width: 1440px) {
        max-height: calc((100vh - 100px) - 5rem);
      }
    }
    &__slide {
      height: auto;
      margin: 0;
      padding: 0;
    }
    &__image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    &__link-wrapper {
      padding: 0;
      margin: 0;
    }
    &__link-item {
      list-style-type: none;
      margin-bottom: .5rem;
    }
    &__link {
      color: inherit;
      text-decoration: none;
      display: flex;
      align-items: center;
      cursor: pointer;
      &--bold {
        font-weight: bold;
      }
    }
    &__link-icon {
      width: 13px;
      margin-left: .4rem;
      transform: translate(0,1px);
      height: 100%;
    }
    &__slider-trigger-list {
      display: flex;
      padding: 0;
      margin: 0;
    }
    &__slider-trigger-list-item {
      padding: 0;
      margin: 0;
      list-style-type: none;
      margin: 2rem 1rem 1rem 0;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
    }
    &__slider-trigger-icon {
      height: 30px;
      width: auto;
    }
    &__breadcrumb-list {
      display: flex;
      padding: 0;
      margin: 0;
      font-size: 13pt;
      margin-top: 2rem;
    }
    &__breadcrumb-list-item {
      padding: .5rem .8rem;
      margin: 0;
      border-radius: 25px;
      background-color: rgba(255,255,255,.2);
      margin-right: 1rem;
      list-style-type: none;
      transition: all 300ms;
      cursor: pointer;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      &:hover {
        background-color: rgba(255,255,255,1);
        color: #0f6cb7;
      }
    }
  }
</style>
